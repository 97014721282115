<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h5>
            {{ $t("shopping.invoices_list") }}
            <b>{{ service.label }}</b> {{ $t("common.available_s") }}
          </h5>
        </div>
        <div class="col-md-auto text-right">
          <div
            class="be-row justify-content-center align-items-center f-14 mt-2 mt-sm-0"
          >
            <span class="be-switch-label">{{ $t("shopping.buy_all") }}</span>
            <div class="switch be-switch m-l-10">
              <input id="switch-bill-buy-all" type="checkbox" />
              <label class="cr mb-0" for="switch-bill-buy-all"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-2">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <ShoppingBillItem
            v-for="(bill, index) in bills"
            :key="index"
            :bill="bill"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ShoppingBillItem from "../../components/shopping/ShoppingBillItem";

export default {
  name: "ShoppingBills",
  components: { ShoppingBillItem },
  props: {
    service: {
      type: Object,
      required: true,
    },
    userService: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState("shopping", ["bills"]),
  },
};
</script>

<style scoped></style>

<template>
  <div class="card table-card">
    <label for="radio-p-fill-5" class="row-table">
      <div class="col-auto theme-bg text-white p-t-50 p-b-50">
        <img
          src="https://www.lohce.com/img/logo_agency/finexs_logo2.jpg"
          class="img-fluid f-30"
        />
      </div>
      <div class="col">
        <div class="form-group float-right d-inline">
          <div class="radio radio-primary d-inline">
            <input type="radio" name="radio-p-fill-5" id="radio-p-fill-5" />
            <label for="radio-p-fill-5" class="cr"></label>
          </div>
        </div>
        <h4 class="text-uppercase f-w-900 d-block m-b-10">
          6000 <small>{{ currency }}</small>
        </h4>
        <span class="d-block"
          ><i class="fa fa-map-pin p-0"></i> Yaoundé (Mvan), 15:00</span
        >
        <span class="d-block"
          ><i class="fa fa-map-pin p-0"></i> Douala (Akwa), 19:00
          <span>
            <span class="d-block"
              ><i class="fa fa-calendar-check p-0"></i> mardi, 25 août 2020<span
              > </span></span></span
        ></span>
      </div>
    </label>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ShoppingBillItem",
  props: {
    bill: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("auth", ["currency"])
  }
};
</script>

<style scoped></style>
